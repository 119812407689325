<template>
    <div class="list-view">
        <page-title>
            <el-button @click="openEdit()" type="primary">新增</el-button>
        </page-title>
        <div class="filters-container">
            <el-form :model="formData" :inline="true">

            </el-form>

            <div class="tool-wrapper">

                <el-input
                    placeholder="搜索..."
                    v-model="search"
                    clearable
                    class="filter-item search"
                    @keyup.enter.native="initData"
                >
                    <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
                </el-input>
            </div>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="enName" label="英文名称" > </el-table-column>
            <el-table-column prop="chName" label="中文名称" > </el-table-column>
            <el-table-column prop="frontAddr" label="前端地址"   min-width="140" show-overflow-tooltip>
                <template   slot-scope="scope">
                    <a :href="scope.row.frontAddr"  target="_blank">{{scope.row.frontAddr}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="userPrefix" label="用户名前缀" > </el-table-column>
             <el-table-column prop="createdAt" label="创建时间" width="150">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100">
                <template slot-scope="scope">
                    <el-button @click="openEdit(scope.row)" size="mini" plain >修改</el-button>
                    <el-button @click="onDelete(scope.row)" size="mini" plain type="danger">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>

        <el-dialog :title="editTitle"  :visible.sync="editVisible">
            <el-form :model="editForm"  :rules="editRules" ref="editForm"  label-width="108px" >
                <el-form-item prop="id" label="项目ID">
                        <el-input-number v-model="editForm.id" placeholder="请输入项目ID" :disabled="!canEdit"  ></el-input-number>
                </el-form-item>
                 <el-form-item prop="enName" label="英文名称">
                        <el-input v-model="editForm.enName" placeholder="请输入英文名称"  ></el-input>
                </el-form-item>
                 <el-form-item prop="chName" label="中文名称">
                        <el-input v-model="editForm.chName" placeholder="请输入中文名称"  ></el-input>
                </el-form-item>
                <el-form-item prop="frontAddr" label="前端地址">
                        <el-input v-model="editForm.frontAddr" placeholder="请输入前端地址，用于回调跳转" ></el-input>
                </el-form-item>
                <el-form-item prop="userPrefix" label="用户名前缀">
                        <el-input v-model="editForm.userPrefix" placeholder="请输入用户名前缀，用于用户名称生成" ></el-input>
                </el-form-item>
                <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onClose" :disabled="saving">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import pageableTable from '@/mixins/pageableTable';
import SortableHeader from '../components/SortableHeader.vue';

export default {
    components: { SortableHeader },
    name: 'projectList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: true,
            search: '',
            url: '/project/all',
            downloading: false,
            formData:{
                 createdAt: "",
            },
            canEdit: true,
            editTitle:"新增",
            editForm:{
                id:null,
                enName:"",
                chName:"",
                frontAddr:"",
                userPrefix:"",
                edit: false
            },
            editRules:{
                id: [
                    {
                        required: true,
                        message: '请输入ID',
                        trigger: 'blur'
                    }
                ],
                enName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                chName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                frontAddr: [
                    {
                        required: true,
                        message: '请输入前端地址',
                        trigger: 'blur'
                    }
                ],
            },
            editVisible: false,
            saving: false
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return {
                search: this.search,
                query: { del: false, ...this.formData}
            };
        },
        afterGetData(){


        },
        openEdit(rowData){
            this.editVisible = true;
            if(rowData){
                this.editTitle = "修改";
                this.$nextTick(() => {
                    this.editForm = Object.assign({}, this.editForm, rowData);
                    this.editForm.edit = true;
                });
                this.canEdit = false;

            }else{
                this.editTitle = "新增";
                this.$nextTick(() => {
                    this.$refs.editForm.resetFields();
                    this.$refs.editForm.id = null;
                    this.editForm.edit = false;
                });
                this.canEdit = true;


            }

        },
        onSave(){

            this.$refs.editForm.validate((valid) => {
                if(valid){
                   this.saving = true;
                   this.$http.post("/project/save", this.editForm, {body:"json"}).then((data) => {
                        this.$message.success("操作成功");
                        this.onClose();
                        this.initData();
                    }).catch((err) =>{
                        this.$message.error(err.error);
                    }).finally(() =>{
                        this.saving = false;
                    });
                }
            });

        },
        onClose(){
            if(this.$refs.editForm){
                this.$refs.editForm.resetFields();
            }

            this.editVisible = false;
        },
        onDelete(rowData){
            if(!rowData){
                this.$message.error("需要参数");
                return;
            }

            this.$confirm(`是否删除项目'${rowData.id}'`).then(() => {
                this.$http.post("/project/delete", rowData, {body:"json"}).then(() => {
                    this.$message.success("操作成功");
                    this.initData();
                }).catch((err) =>{
                    this.$message.error(err.error);
                }).finally(() =>{
                    this.saving = false;
                });
            });

        }

    }
};
</script>
<style lang="less" scoped>

.tool-wrapper{
    display:block;
    overflow: hidden;

    .tool-right{
        float:left;
    }
}
</style>
